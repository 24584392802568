import url from "../Images/Icons/url.png";
import mainImg from "../Images/Contents/Hasun/hasun_main.png";
import { updateHeader } from "./Data_Func";

const HasunHeader = {
  stage: "Detail",
  path: "hasun",
  title: "Hasun Kang Portfolio",
  desc: <>Hasun Kang 작가의 포트폴리오 사이트</>,
  channelArray: [
    {
      url: "https://hasunkang.com",
      platform: url,
    },
  ],
  mainImg: mainImg,
  tags: [],
  lastUpdate: { date: null, title: null, index: null },
  introArray: [],
};

const HasunContents = [
  {
    date: "2023/04/15",
    title: "Hasunkang 포트폴리오 제작",
    desc: ["React를 활용한 포트폴리오 사이트 제작"],
    tags: ["개발"],
    channelArray: [
      {
        url: "https://hasunkang.com",
        platform: url,
      },
    ],
  },
];

updateHeader(HasunHeader, HasunContents);

export { HasunHeader, HasunContents };
