import url from "../Images/Icons/url.png";
import instagram from "../Images/Icons/instagram.png";
import brunch from "../Images/Icons/brunch.png";
import mainImg from "../Images/Contents/Amd/amd_main.png";
import img1 from "../Images/Contents/Amd/amd_1.png";
import img2 from "../Images/Contents/Amd/amd_2.png";
import { updateHeader } from "./Data_Func";

const AmdHeader = {
  stage: "Detail",
  path: "amd",
  title: "오늘도 점을 찍으며 살고 있습니다.",
  desc: <>사이드 프로젝트에 대한 단상집</>,
  channelArray: [
    {
      url: "https://www.instagram.com/about.my.dots/",
      platform: instagram,
    },
    {
      url: "https://naver.me/xJaVweIl",
      platform: url,
    },
  ],
  mainImg: mainImg,
  tags: [],
  lastUpdate: { date: null, title: null, index: null },
  introArray: [
    // {
    //   type: "Book Store",
    //   itemArray: [
    //     {
    //       item: "스토리지 북앤필름 강남점",
    //       url: "https://www.instagram.com/at_storage/",
    //       status: null,
    //     },
    //     {
    //       item: "북다마스",
    //       url: "https://www.instagram.com/book_damas/",
    //       status: null,
    //     },
    //   ],
    // },
  ],
};

const AmdContents = [
  {
    date: "2023/10/17",
    img: img2,
    title: "에세이 브런치북 출간",
    desc: ["에세이 퇴고", "에세이 브런치북 출간"],
    tags: ["작가"],
    channelArray: [
      {
        platform: brunch,
        url: "https://brunch.co.kr/brunchbook/aboutmydots",
      },
    ],
  },
  {
    date: "2022/09/15",
    img: img1,
    title: "에세이 독립 출판",
    desc: ["에세이 집필/첨삭", "에세이 독립 출판"],
    tags: ["작가", "출판"],
    channelArray: [
      {
        url: "https://www.instagram.com/about.my.dots/",
        platform: instagram,
      },
      {
        url: "https://naver.me/xJaVweIl",
        platform: url,
      },
    ],
  },
];

updateHeader(AmdHeader, AmdContents);

export { AmdHeader, AmdContents };
