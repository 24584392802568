import brunch from "../Images/Icons/brunch.png";
import instagram from "../Images/Icons/instagram.png";
import mainImg from "../Images/Contents/Nbp/nbp_main.png";
import url from "../Images/Icons/url.png";
import img2 from "../Images/Contents/Nbp/nbp_2.png";
import img1 from "../Images/Contents/Nbp/nbp_1.png";

import { updateHeader } from "./Data_Func";

const NbpHeader = {
  stage: "Detail",
  path: "nbp",
  title: "출판전야",
  desc: (
    <>
      몽상가들을 위한<p></p>쉼표와 마침표가 있는 은신처
    </>
  ),
  channelArray: [
    {
      url: "https://www.instagram.com/night.before.publishing/",
      platform: instagram,
    },
  ],
  mainImg: mainImg,
  tags: [],
  lastUpdate: { date: null, title: null, index: null },
  introArray: [],
};

const NbpContents = [
  {
    date: "2022/04/17",
    img: img2,
    ratio: "16:9",
    title: "출판전야 웹사이트 오픈",
    desc: ["출판전야 웹사이트 개발", "출판전야 웹사이트 제작기 작성"],
    tags: ["기획", "디자인", "개발", "콘텐츠"],
    channelArray: [
      {
        url: "https://nightbeforepublishing.com/",
        platform: url,
      },
      {
        url: "https://brunch.co.kr/@vicotorlee/185",
        platform: brunch,
      },
    ],
  },
  {
    date: "2022/02/21",
    img: img1,
    ratio: "16:9",
    title: "출판전야 인사이트 아카이브",
    desc: ["인스타그램 채널 개설", "인스타그램 채널 운영"],
    tags: ["콘텐츠"],
    channelArray: [
      {
        url: "https://www.instagram.com/night.before.publishing/",
        platform: instagram,
      },
    ],
  },
];

updateHeader(NbpHeader, NbpContents);

export { NbpHeader, NbpContents };
