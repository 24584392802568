import mainImg from "../Images/Contents/Ssd/ssd_main.png";
import brunch from "../Images/Icons/brunch.png";
import img1 from "../Images/Contents/Ssd/ssd_1.png";
import img2 from "../Images/Contents/Ssd/ssd_2.png";
import img3 from "../Images/Contents/Ssd/ssd_3.png";
import img4 from "../Images/Contents/Ssd/ssd_4.png";
import img5 from "../Images/Contents/Ssd/ssd_5.png";
import img6 from "../Images/Contents/Ssd/ssd_6.png";
import img7 from "../Images/Contents/Ssd/ssd_7.png";
import img8 from "../Images/Contents/Ssd/ssd_8.png";
import img9 from "../Images/Contents/Ssd/ssd_9.png";
import { updateHeader } from "./Data_Func";

const SsdHeader = {
  stage: "Detail",
  path: "ssd",
  title: "숨쉬듯",
  desc: (
    <>
      숨 쉬듯 글 쓰는 모임<p></p>'숨쉬듯'에서 쓴 글들
    </>
  ),
  channelArray: [
    {
      url: "https://brunch.co.kr/magazine/xiaoshuo",
      platform: brunch,
    },
  ],
  mainImg: mainImg,
  tags: [],
  lastUpdate: { date: null, title: null, index: null },
  introArray: [],
};

const SsdContents = [
  {
    date: "2023/12/11",
    img: img9,
    title: "주변인 집필",
    desc: ["머리 위에 뜬 숫자를 소재로 한 단편소설 집필"],
    tags: ["작가"],
    channelArray: [
      {
        platform: brunch,
        url: "https://brunch.co.kr/@vicotorlee/248",
      },
    ],
  },
  {
    date: "2023/11/18",
    img: img8,
    title: "차마기행 집필",
    desc: ["버릇을 소재로 한 단편소설 집필"],
    tags: ["작가"],
    channelArray: [
      {
        platform: brunch,
        url: "https://brunch.co.kr/@vicotorlee/246",
      },
    ],
  },
  {
    date: "2023/10/27",
    img: img7,
    title: "포인트 제로 집필",
    desc: ["제로(0)를 소재로 한 단편소설 집필"],
    tags: ["작가"],
    channelArray: [
      {
        platform: brunch,
        url: "https://brunch.co.kr/@vicotorlee/245",
      },
    ],
  },
  {
    date: "2023/09/24",
    img: img6,
    title: "몽중인(梦中人) 집필",
    desc: ["특정 예술 작품에 나온 문장을 소재로 한 단편소설 집필"],
    tags: ["작가"],
    channelArray: [
      {
        platform: brunch,
        url: "https://brunch.co.kr/@vicotorlee/220",
      },
    ],
  },
  {
    date: "2023/05/23",
    img: img5,
    title: "Lonely Keyboard 집필",
    desc: ["키보드를 소재로 한 단편소설 집필"],
    tags: ["작가"],
    channelArray: [
      {
        platform: brunch,
        url: "https://brunch.co.kr/@vicotorlee/217",
      },
    ],
  },
  {
    date: "2023/04/15",
    img: img4,
    title: "기일일기 집필",
    desc: ["일기를 소재로 한 단편소설 집필"],
    tags: ["작가"],
    channelArray: [
      {
        platform: brunch,
        url: "https://brunch.co.kr/@vicotorlee/201",
      },
    ],
  },
  {
    date: "2023/03/18",
    img: img3,
    title: "판도라 집필",
    desc: ["타고난 재능을 소재로 한 단편소설 집필"],
    tags: ["작가"],
    channelArray: [
      {
        platform: brunch,
        url: "https://brunch.co.kr/@vicotorlee/200",
      },
    ],
  },
  {
    date: "2023/01/19",
    img: img2,
    title: "0.702 집필",
    desc: ["랜덤하게 뽑힌 숫자 0702를 소재로 한 단편소설 집필"],
    tags: ["작가"],
    channelArray: [
      {
        platform: brunch,
        url: "https://brunch.co.kr/@vicotorlee/197",
      },
    ],
  },
  {
    date: "2023/01/04",
    img: img1,
    title: "다이브(DivE) 집필",
    desc: ["다이브를 소재로 한 SF 단편소설 집필"],
    tags: ["작가"],
    channelArray: [
      {
        platform: brunch,
        url: "https://brunch.co.kr/@vicotorlee/196",
      },
    ],
  },
];

updateHeader(SsdHeader, SsdContents);

export { SsdHeader, SsdContents };
