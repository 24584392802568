const getUniqueTags = (header, contents) => {
  let tags = [];
  let tagRanking = [];
  contents.map((content) => {
    tags = [...tags, ...content.tags];
  });
  const uniqueTags = Array.from(new Set(tags));
  uniqueTags.map((tag) => {
    const tagCount = tags.filter((element) => tag === element).length;
    tagRanking = [...tagRanking, { item: tag, count: tagCount }];
  });
  const tagRankingResult = tagRanking.sort((a, b) => b.count - a.count);
  header.tags = [...uniqueTags];
  header.introArray = [
    ...header.introArray,
    {
      type: "Tags",
      itemArray: [...tagRankingResult],
    },
  ];
};

const getLastUpdate = (header, contents) => {
  const lastUpdate = {
    date: contents[0].date,
    title: contents[0].title,
    index: contents.length,
  };
  header.lastUpdate = { ...header.lastUpdate, ...lastUpdate };
};

const updateHeader = (header, contents) => {
  getUniqueTags(header, contents);
  getLastUpdate(header, contents);
};

export { updateHeader };
